.footer {
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.footer-info {
  display: flex;
  flex-direction: row;

  &>div:not(:first-child)::before {
    content: "|";
    margin: 0rem 0.5rem;
  }
}

.footer-help {
  button {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media only screen and (max-width: @screen-threshold-widescreen) {}

@media only screen and (max-width: @screen-threshold-vertical) {
  .footer-info {
    flex-direction: column;

    &>div:not(:first-child) {
      margin-top: 0.5rem;
    }

    &>div:not(:first-child)::before {
      content: "";
      margin: 0rem;
    }
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .ant-layout-footer {
    padding: 0.5rem 1rem;
  }

  .footer {
    flex-direction: column;
  }

  .footer-info {}
}