.thermometer-form-element {
  margin: 1rem auto 0rem auto;
  .ant-slider {
    height: 2.5rem;
  }
  .ant-slider-rail {
    width: auto;
    left: -1rem;
    right: -1rem;
    border-radius: 0.5rem;
    height: 2rem;
    background: linear-gradient(90deg, rgba(24,144,255,1) 0%, rgba(245,34,45,1) 100%);
  }
  .ant-slider:hover .ant-slider-rail {
    background: linear-gradient(90deg, rgba(24,144,255,1) 0%, rgba(245,34,45,1) 100%);
  }
  .ant-slider-track {
    display: none;
  }
  .ant-slider-step {
    height: 2rem;

    .ant-slider-dot {
      top: 0;
      opacity: 0.5;
      margin: 0.5rem 0;
      height: 1rem;
      width: 1rem;
    }

    .ant-slider-dot-active {
      border-color: rgba(224, 224, 224, 0.534);
    }
  }
  .ant-slider-handle {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: -0.25rem;
  }
}

.thermometer-form-labels {
  display: flex;
  justify-content: space-between;
}
