.preference-single-opinion-form {
  .preference-single-opinion-form-element {
    .form-element-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: stretch;

      .form-element-container {
        flex: 0 0 33%;
        padding: 0.25rem;
        min-height: 15rem;

        .preference-container-wrapper {
          .preference-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: @workflow-background;
            box-sizing: border-box;
      
            &.selected {
              background: #E8E8E8;
            }

            &.has-error {
              border: 2px solid @error-color;
            }
        
            .preference-stimulus-wrapper {
              .preference-stimulus-link {
                background-size: cover;
                cursor: pointer;
                min-height: 10rem;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
          
                .preference-view-link {
                  text-align: center;
                  min-height: 2rem;
                  color: @primary-color;
                  font-weight: bolder;
                  font-size: 0.9rem;
                  z-index: 10;
                  pointer-events: none;
                }
              }

              .preference-view-link {
                text-align: center;
                height: 2rem;
                color: @primary-color;

                .ant-btn {
                  padding: 0.25rem 2rem;
                  height: 2rem;
                }
              }
            }

            .preference-choice-button {
              padding: 0.5rem;
              width: 100%;
              flex: 0 1 auto;

              .preference-select-btn {
                white-space: unset;
                height: auto;
                padding: 0.25rem 1rem;
                width: 100%;
                height: 100%;

                &.selected {
                  background: @primary-color;
                }
              }
            }
          }
          
          .ant-form-item-explain-error {
            display: none;
          }

          .preference-initiative-text {
            display: flex;
            justify-content: center;
            box-sizing: border-box;
      
            .prerequisite-error {
              background-color: transparent;
              border: none;
              padding: 0;
    
              .ant-alert-message {
                color: @error-color;
              }
            }
          }
        }
      }
    }

    .ant-form-item-explain-connected {
      .ant-form-item-explain-error {
        display: none;
      }
    }

    &.mobile-layout {
      .form-element-wrapper {
        flex-direction: column;
        flex-wrap: nowrap;

        .form-element-container {
          flex: 0 0 100%;

          .preference-container {
            flex-direction: column;
            
            .preference-stimulus-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.preference-stimuli-overlay-wrapper {
  max-width: 86rem;

  &.mobile-layout {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      padding: 3.5rem 0 0.5rem 0;
  
      .ant-carousel {
        .slick-prev {
          left: 9rem;
        }
  
        .slick-next {
          right: 9rem;
        }
  
        .slick-prev,
        .slick-next {
          top: 95%;
  
        }
  
        .with-arrows {
          padding: 0 0 3rem 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .preference-single-opinion-form {
    .preference-single-opinion-form-element {
      .form-element-wrapper {
        .form-element-container {
          flex: 0 1 50%;
        }
      }
    }
  }
}
