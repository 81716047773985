@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

// primary color for all components
@primary-color: #ff9500;
@processing-color: @primary-color;
@white-color: #FFF;
@black-color: #000;
@record-color: #f5222d;
// link color
@link-color: #1890ff;
// success state color
@success-color: #52c41a;
// warning state color
@warning-color: #faad14;
// error state color
@error-color: #f5222d;
// major text font size
@font-size-base: 14px;
// heading text color
@heading-color: rgba(0, 0, 0, 0.85);
@font-size-base: 18px;
// major text color
@text-color: rgba(0, 0, 0, 0.65);
@text-color-light: #F5F5F5;
// secondary text color
@text-color-secondary: rgba(0, 0, 0, 0.45);
// disable state color
@disabled-color: rgba(0, 0, 0, 0.25);
// major border radius
@border-radius-base: 2px;
// major border color
@border-color-base: #d9d9d9;
// major shadow for layers
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.16), 0 6px 16px 0 rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

@layout-header-height: auto;
@layout-header-background: #f0f2f5;
@layout-header-padding: 0rem;

@layout-body-background: #f0f2f5;

@layout-footer-background: #fff;
@layout-footer-padding: 1rem 3rem;

.ant-btn {
  border-radius: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 3rem;
}

.ant-form-item-label>label {
  align-items: baseline;
}