.matrix-form-element {
  // border: 2px solid #ebebeb;
  position: relative;
  max-height: 80vh;
  overflow: auto;
  margin-bottom: 3rem;
    /* width */
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ebebeb;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9c9c9c;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #6b6b6b;
  }

  .matrix-form-element-inner {
    display: table;
    width: 100%;
  }

  &.mobile-layout {
    overflow: unset;

    .matrix-form-element-inner {
      display: block;
      width: auto;
    }
  }
}

.matrix-form-element-header-row,
.matrix-form-element-body-row {
  display: table-row;
}

.matrix-form-element-body-row:nth-child(even) {
  .matrix-form-element-body-row-cell,
  .matrix-form-element-body-row-cell-label {
    background-color: #ebebeb;
  }
}

.matrix-form-element-header-row-cell,
.matrix-form-element-body-row-cell,
.matrix-form-element-body-row-cell-label {
  display: table-cell;
  background-color: #f5f6f8;
}

.matrix-form-element-body-row-cell {
  vertical-align: middle;
  position: relative;
}

.matrix-form-element-header-row-cell {
  font-weight: 600;
  font-size: 0.8rem;
  padding: 1rem 0.5rem;
  vertical-align: top;
  position: sticky;
  top: 0px;
  z-index: 2;
  vertical-align: bottom;
  border-bottom: 1px solid #ebebeb;
  &:not(:first-child) {
    min-width: 6rem;
  }
}

.matrix-form-element-body-row-cell-label {
  font-weight: 600;
  padding: 1rem;
  vertical-align: top;
  position: sticky;
  left: 0px;
  z-index: 1;
  width: 15vw;
  max-width: 20rem;
  font-size: 0.8rem;
}

.matrix-form-element-body-row-error {
  color: @error-color;
  font-size: 14px;
  line-height: 1.5715;
}