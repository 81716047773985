@import './footer.less';
@import './header.less';
@import './workflowActivity/index.less';
@import './workflowElement/index.less';
@import './routeTransition.less';
@import './content/index.less';
@import './dragConfirmOverlay.less';

.activity-layout {
  display: flex;
  align-items: stretch;
}

.centered-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.prerequisite-error {
  margin: 0.5rem 0rem;
}
