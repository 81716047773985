.wistia-external-video-workflow-element {
  height: 40rem;
  width: 100%;
  max-width: 65rem;
  margin: 0rem auto;
}
.vimeo-external-video-workflow-element {
  iframe {
    height: 40rem;
    width: 100%;
  }
}


@media only screen and (max-width: @screen-threshold-widescreen) {
  .wistia-external-video-workflow-element {
    height: 32rem;
    max-width: 55rem;
  }
  .vimeo-external-video-workflow-element {
    iframe {
      height: 32rem;
    }
  }
}

@media only screen and (max-width: @screen-threshold-vertical) {
  .wistia-external-video-workflow-element {
    height: 30rem;
    max-width: 50rem;
  }
  .vimeo-external-video-workflow-element {
    iframe {
      height: 30rem;
    }
  }
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .wistia-external-video-workflow-element {
    height: 20rem;
    max-width: 30rem;
  }
  .vimeo-external-video-workflow-element {
    iframe {
      height: 20rem;
    }
  }
}