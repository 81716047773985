.drag-confirm-wrapper {
  position: relative;

  .drag-confirm-content {
    display: block;
  }

  &:not(.confirmed) {
    .drag-confirm-content {
      filter: blur(8px);
    }
  }

  .drag-confirm-overlay {
    box-sizing: border-box;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;

    .drag-confirm-overlay-content {
      border-radius: 0.5rem;
      padding: 2rem;
      max-width: 15rem;
    
      button {
        cursor: pointer;
      }

      .drag-confirm-overlay-label {
        margin-bottom: 1rem;
      }
    }
  }
}