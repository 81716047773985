@import './videoRatingRadio.less';
@import './textRatingRadio.less';
@import './scale.less';
@import './npsRadio.less';
@import './scaleSlider.less';
@import './thermometer.less';
@import './matrix.less';
@import './ratingMeasureRadio.less';
@import './preferenceOpinion.less';
@import './preferenceSingleOpinion.less';
@import './genderRadio.less';
@import './rankingOpinion.less';

.form-actions {
  text-align: center;
  margin-top: 2rem;
}

.ant-radio-group {
  width: 100%;

  .ant-space {
    width: 100%;

    .ant-radio-wrapper-in-form-item {
      display: flex;
      flex-direction: row;
    
      span:not(.ant-radio) {
        flex: 1 1 auto;
      }
    }
  }
}

.form-element-checkbox-group {
  display: flex;
  flex-direction: column;

  .form-element-checkbox {
    display: flex;
    flex-direction: row;
    margin-left: 0;

    span:not(.ant-checkbox) {
      flex: 1 1 auto;
    }
  }
}

.form-element-checkbox-other-input,
.form-element-radio-other-input {
  max-width: 36rem;
  width: 100%;
}

@media only screen and (max-width: @screen-threshold-mobile) {
  .form-element-checkbox-other-input,
  .form-element-radio-other-input {
    width: 100%;
  }
}